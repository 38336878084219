<template>
  <Badge
    class="rebate-badge text--bold"
    :magenta="isMagentaTheme"
  >
    <span
      v-if="preText"
      class="rebate-badge__pre-text"
    >
      {{ preText }}
    </span>
    <span class="text badge--size-200 text--highlight">
      {{ humanizePriceFromCents(defaultPrice) }}
    </span>
    <br />
    <template v-if="wasPrice > 0">
      <span class="text">statt</span>
      <span class="text text--struckthrough-light">{{ humanizePriceFromCents(wasPrice) }}</span>
    </template>
  </Badge>
</template>

<script>
import { Badge } from '@i22-td-smarthome/component-library';
import { humanizePriceFromCents } from '@/lib/price';

export default {
  name: 'RebateBadge',
  components: { Badge },
  props: {
    defaultPrice: {
      type: Number,
      required: true,
    },
    isMagentaTheme: { type: Boolean, default: false },
    preText: { type: String, default: '' },
    wasPrice: {
      type: Number,
      default: 0,
    },
  },
  methods: { humanizePriceFromCents },
};
</script>

<style lang="scss">
@import 'assets/base';
.rebate-badge {
  .badge__content {
    @include font-size(10px, 14px);
    padding: 0.5em;
    @include mq($mq-sm) {
      @include font-size(14px, 18px);
    }
  }

  .text--struckthrough-light {
    &::after {
      transform: skewY(-10deg) translateY(-50%);
    }
  }
}
</style>
