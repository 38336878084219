import { getPrice } from '@i22/rocket';
import type { Offer } from '@/lib/goliath/offers';

const defaultBackground =
  'conic-gradient(#e2e2e2 0deg 90deg,#ff9a1e 90deg 180deg,#2ba2e0 180deg 270deg,#505050 270deg 1turn)';

function getOffer<O extends Offer>(offers: O[], articleNumber: string): O | undefined {
  if (!offers) return undefined;
  const offer = offers.find((e) => e?.product?.articleNumber === articleNumber);
  return offer !== null ? offer : undefined;
}

function getTeasersWithOffers(offers: Offer[], teasers: any[]) {
  return teasers
    .map((teaser) => {
      const offer = getOffer(offers, teaser.articleNumber) || [];
      return Object.keys(offer).length > 0 ? { ...teaser, offer } : null;
    })
    .filter((teaser) => teaser !== null);
}

function getColorAlternatives<O extends Offer>(offerEntry: O | undefined) {
  const productAlternatives = offerEntry?.productAlternatives || [];
  const colorAlternative = productAlternatives.find((productAlternative) =>
    productAlternative?.properties?.find((p) => p?.key === 'COLOR')
  );

  return (
    colorAlternative?.properties?.filter((prop) => prop?.key === 'COLOR')[0]?.alternatives || []
  );
}

export type ListAlternativeOffer = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<NonNullable<NonNullable<Offer>['productAlternatives']>[number]>['properties']
        >[number]
      >['alternatives']
    >[number]
  >['offer']
>;

export type ListAlternativeImages = NonNullable<ListAlternativeOffer['teaserImage']>;

export type ColorAlternativeEntry = {
  active: boolean;
  articleNumber: string;
  colorCode: string;
  path: string;
  thumbnail: NonNullable<ListAlternativeImages['sources']>;
  teaserImage: NonNullable<ListAlternativeImages> | undefined;
  price: {
    default: number | undefined;
    was: number | undefined;
  };
  stock: NonNullable<ListAlternativeOffer['product']>['stock'];
  slug: string;
  offer: ListAlternativeOffer;
};

export type ColorAlternative = {
  title: string;
  articleNumber: string;
  colorAlternatives: ColorAlternativeEntry[];
};

function alternative<O extends Offer>(offer: O | undefined): ColorAlternative {
  if (!offer) {
    return {
      title: '',
      articleNumber: '',
      colorAlternatives: [],
    };
  }
  const entryPropertyAlternatives = getColorAlternatives(offer).filter(
    (a) => a?.offer?.prices && a.offer.prices.length > 0
  );
  const offerColorAlternatives = entryPropertyAlternatives
    .map((a) => {
      if (!a?.offer?.product) return undefined;
      const colorAlternative: ColorAlternativeEntry = {
        active: a.active || false,
        articleNumber: a.offer.product.articleNumber || '',
        colorCode: a.offer.scopedProduct?.color?.code || defaultBackground,
        path: `/geraete/${a.offer.product.slug}`,
        thumbnail: a.offer.teaserImage?.sources || [],
        teaserImage: a.offer.teaserImage || undefined,
        price: {
          // @ts-ignore
          default: getPrice(a.offer.prices || [], 'DEFAULT', 'ONETIME')?.value,
          // @ts-ignore
          was: getPrice(a.offer.prices || [], 'WAS', 'ONETIME')?.value,
        },
        stock: a.offer.product.stock,
        slug: a.offer.product.slug || '',
        offer: a.offer,
      };
      return colorAlternative;
    })
    .filter((ca): ca is ColorAlternativeEntry => !!ca);
  return {
    title: offer.product?.family?.name || '',
    articleNumber: offer.product?.articleNumber || '',
    colorAlternatives: offerColorAlternatives || [],
  };
}

// This is used for offers that do not have alternatives but for example the consuming component requires an alternative object
function buildAlternativeEntry<O extends Offer>(offer: O): ColorAlternativeEntry | undefined {
  if (!offer) return undefined;
  return {
    active: true,
    articleNumber: offer?.product?.articleNumber || '',
    colorCode: defaultBackground,
    path: `/geraete/${offer?.product?.slug}`,
    thumbnail: offer?.teaserImage?.sources || [],
    teaserImage: offer?.teaserImage || undefined,
    price: {
      // @ts-ignore
      default: getPrice(offer?.prices || [], 'DEFAULT', 'ONETIME')?.value,
      // @ts-ignore
      was: getPrice(offer?.prices || [], 'WAS', 'ONETIME')?.value,
    },
    stock: offer?.product?.stock,
    slug: offer?.product?.slug || '',
    offer: {},
  };
}

function alternatives<O extends Offer>(offers: O[]) {
  if (!offers) return [];
  return offers.map((offer) => alternative(offer));
}

export {
  getTeasersWithOffers,
  getColorAlternatives,
  alternatives,
  alternative,
  buildAlternativeEntry,
};
