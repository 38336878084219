import uniqBy from 'lodash/uniqBy';
import type { Offer } from '@/lib/goliath/offers';
import type { ScopedOffer } from '@/lib/goliath/goliath.types';

export type MediaSource = NonNullable<
  NonNullable<NonNullable<NonNullable<NonNullable<Offer>['media']>[number]>['sources']>[number]
>;

export type OfferImage = {
  name: string | undefined;
  sources: MediaSource[];
  url: string;
};

export const getImagePathByWidth = (
  sourceArray: MediaSource[] | null | undefined,
  minWidth: number | null = null,
  maxWidth: number | null = null,
  fallback = false
): string | undefined => {
  if (!sourceArray) return undefined;
  const image = sourceArray.find((src) => {
    if (src === null) return false;
    if (!src.meta?.width) return false;
    if (minWidth !== null && maxWidth !== null) {
      return src.meta.width <= maxWidth && src.meta.width >= minWidth;
    }
    if (minWidth !== null) return src.meta.width >= minWidth;
    return maxWidth ? src.meta.width <= maxWidth : false;
  });
  if (!image && fallback) return sourceArray?.[0]?.path || undefined;
  return image?.path || undefined;
};

export const getImagesFromOffer = <O extends Offer | ScopedOffer>(offer: O): OfferImage[] => {
  const baseImages = offer?.media || [];
  const product = offer?.product || {};
  const bundledProducts = 'bundledProducts' in product ? product.bundledProducts || [] : [];
  const additionalImages = bundledProducts.map((i) => i?.teaserImage);
  const uniqueAdditionalImages = uniqBy(additionalImages, 'path');
  const offerImages: OfferImage[] = [];
  [...baseImages, ...uniqueAdditionalImages].forEach((media) => {
    if (!media || media.type?.toLowerCase() !== 'image') return;
    // @ts-ignore
    const sources: MediaSource[] =
      media.sources?.filter((src): src is MediaSource => src !== null) || [];
    if (sources.length === 0) return;

    const url = getImagePathByWidth(sources, 500, 1000, true);
    if (!url) return;
    offerImages.push({
      name: media.name || undefined,
      sources,
      url,
    });
  });

  return offerImages;
};

export const getMainImageFromOffer = <O extends Offer>(offer: O, index = 0) => {
  const offerImages = getImagesFromOffer(offer);
  if (offerImages.length < index + 1) return [];
  return offerImages[index]?.sources || [];
};
