<template>
  <nuxt-link
    v-if="!isExternal"
    :data-tealium-rel="slugify(webtrack, true)"
    :to="url"
    class="btn"
  >
    <slot />
  </nuxt-link>
  <a
    v-else
    class="btn"
    :href="url"
    :data-tealium-rel="slugify(webtrack, true)"
    target="_blank"
  >
    <slot />
  </a>
</template>

<script>
import { slugify } from '@i22-td-smarthome/component-library';

export default {
  name: 'StageLink',
  props: {
    isExternal: { type: Boolean },
    url: { type: String, required: true },
    webtrack: { type: String, default: '' },
  },
  methods: {
    slugify,
  },
};
</script>
